@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;700&display=swap");

*,
*::after,
*::before {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Nunito", sans-serif;
}

html {
	background-color: #110101;
}

html,
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
	width: 100%;
	height: 100%;
}
